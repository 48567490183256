import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// import BlogPage from './pages/BlogPage';
// import ProductsPage from './pages/ProductsPage';
import ActivitiesPage from './pages/ActivitiesPage';
import ActivityEnrollment from './pages/ActivityEnrollment';
import SmtpPage from './pages/SmtpPage';
import ManageCert from './pages/ManageCert';
import UsersPage from './pages/UsersPage';
import OrganizationsPage from './pages/OrganizationsPage';
import LoginPage from './pages/LoginPage';
import RecoverPassword from './pages/RecoverPassword'
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import RegisterParticipants from './components/forms/registerParticipantsForms/createParticipants/index'
import GetCerts from './pages/GetCerts';
import ValidateAuth from "./utils/validateAuth"
import { useAuth } from './context/AuthContext';
import Tryout from './pages/Tryout';
import TryoutList from './pages/TryoutList';

// ----------------------------------------------------------------------

export default function Router() {

  const {currentUser} = useAuth()

  const tempRoutes = [
    {
      path: '/',
      element: <ValidateAuth component={DashboardLayout}/>,
      children: [
        { element: (currentUser?.userType || false) === "Admin" ? <Navigate to="/organizations" /> : <Navigate to="/activities" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'organizations', element: <OrganizationsPage /> },
        { path: 'users', element: <UsersPage /> },
        // { path: 'activities', element: <ActivitiesPage /> },
        {
          path: '/activities',
          children: [
            { path: '', element: <ActivitiesPage /> },
            { path: 'details/:activityId', element: <ActivityEnrollment /> },
            { path: 'cert/:activityId', element: <ManageCert /> } 
          ]
        },
        {
          path: '/tryoutList',
          children: [
            { path: '', element: <TryoutList /> }
          ]
        },
        {
          path: '/smtp',
          children: [
            { path: '', element: <SmtpPage /> }
          ]
        },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'tryout',
      element: <Tryout />,
    },
    {
      path: 'addParticipants/:activityId?',
      element: <RegisterParticipants />,
    },
    {
      path: 'getCert/:activityId?',
      element: <GetCerts />,
    },
    {
      path: 'recoverPassword/:tempToken?',
      element: <RecoverPassword />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]

  const routes = useRoutes(tempRoutes);

  return routes;
}

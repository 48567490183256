import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../context/AuthContext';

export default function LoginForm() {
  const navigate = useNavigate();
  const { singIn } = useAuth();

  const [showPassword, setShowPassword] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    try {
      setLoading(true);
      await singIn(username, password);
      navigate('/', { replace: true });
    } catch (error) {
      const message = error?.response?.data?.message || "Por favor verifique sus credenciales e intente nuevamente."
      setError(message);
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" size='small' label="Email address" onChange={(e) => { setUsername(e.target.value) }} />

        <TextField
          name="password"
          size='small'
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => { setPassword(e.target.value) }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2, cursor: "pointer" }} onClick={() => navigate("/recoverPassword")}>
        <Link variant="subtitle2" underline="hover">
          ¿Olvidó su contraseña?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}
        disabled={!username || !password || loading}
      >
        {loading ? 'Iniciando...' : 'Iniciar sesión'}
      </LoadingButton>
      < Typography variant="subtitle2" sx={{ textAlign: 'center', color: "red" }} mt={1}>
        {error && error}
      </Typography>

    </>
  );
}

import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Popover,
  MenuItem,
  IconButton
} from '@mui/material';

// cambios realizados para la actividad


// components
import Iconify from '../components/iconify';
// sections
// mock


import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import CustomModal from '../components/modal/CustomModal';

import FormCreateActivities from '../components/forms/registerParticipantsForms/editParticipants'

// hooks
import { useAxios } from '../context/AxiosHookContext';
import { useNotification } from '../context/NotificationContext';
// ----------------------------------------------------------------------



const TABLE_HEAD = [
  { id: 'actividad', label: 'Nombres', alignRight: false },
  { id: 'anfitrion', label: 'Identificacíon', alignRight: false },
  { id: 'limitSpaces', label: 'Cargo', alignRight: false },
  { id: 'anfitrion', label: 'Confirmar visita', alignRight: false },
  { id: 'limitSpaces', label: 'Asiento', alignRight: false },
  { id: 'limitSpaces', label: 'Género', alignRight: false },
  { id: 'lastEnrollDate', label: 'Contacto', alignRight: false },
  { id: 'description', label: 'Email', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------


export default function ActivityEnrollment() {

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [participantsList, setActivitiesList] = useState([]);
  const [activity, setActivity] = useState({})
  const navigate = useNavigate();
  const { activityId } = useParams();
  const { showSnackbar } = useNotification();

  const [addShair, setOpenModalAddshair] = useState(false)



  const handleOpenMenu = (event, values) => {
    setOpen(event.currentTarget);
    setValuesToEdit(values)
  };


  const handleCloseMenu = () => {
    setOpen(null);
  };
  const [valuesToEdit, setValuesToEdit] = useState({})
  const [open, setOpen] = useState(null);

  if (!activityId) {
    navigate("/404")
  }

  const [filterName, setFilterName] = useState('');

  const ACTIVITY_API = `/activities/activities/details/${activityId}`
  const PARTICIPANT_API = `/activities/assistants?actividadId=${activityId}&page=${page}&size=10&nombre=${filterName}`
  const axios = useAxios();

  const getAsistants = useCallback(async () => {
    try {

      setActivitiesList([])
      setLoading(true);
      const { data } = await axios.get(PARTICIPANT_API);
      setActivitiesList(data.items);
      setTotalPages(data.total_pages)

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [axios, PARTICIPANT_API, setActivitiesList, setLoading]);

  const confirm = useCallback(async (participantId) => {
    try {

      await axios.put(`/activities/assistants/${participantId}`, { confirmado: true });

    } catch (error) {

      console.log(error);

    } finally {

      getAsistants()

    }
  }, [axios, getAsistants]);

  const generateReport = async () => {
    try {

      setLoadingReport(true);
      const response = await axios.get(`activities/assistants/asistants/report/${activityId}`, {
        responseType: 'blob' // Indica que esperas una respuesta de tipo Blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace <a> para iniciar la descarga
      const a = document.createElement('a');
      a.href = url;
      a.download = `${activity.actividad}-reporte.xlsx`; // Nombre del archivo para descargar
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      showSnackbar({
        severity: "success",
        message: "Se ha obtenido con éxito el reporte!",
        title: "Reporte obtenido"
      });

    } catch (error) {

      showSnackbar({
        severity: "error",
        message: "Hemos encontrado un inconveniente al buscar tu reporte.",
        title: "Lo sentimos"
      });
    } finally {

      setLoadingReport(false);
    }
  };

  const getActivityDetails = useCallback(async () => {
    try {

      const { data } = await axios.get(ACTIVITY_API);
      setActivity(data)


    } catch (error) {
      console.log(error);
    }
  }, [axios, ACTIVITY_API]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    getActivityDetails()
  }, [getActivityDetails])

  useEffect(() => {
    getAsistants();
  }, [getAsistants, page, filterName])


  return (
    <>

      <Helmet>
        <title>Registros | ASISCORE</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

          <Stack>
            <Typography variant="h4">
              {activity && activity.actividad}
            </Typography>

            <br style={{ margin: 0, padding: 0 }} />

            <Typography component="span" sx={{ fontSize: 13, marginTop: "-25px" }}>
              {activity && activity?.totalGeneral || 0} Registros.
            </Typography>
          </Stack>

          <Button variant="contained" startIcon={<Iconify icon="file-icons:microsoft-excel" />} onClick={generateReport}>
            {loadingReport && <CircularProgress size={'15px'} sx={{ color: 'white', marginRight: '6px' }} />}
            {loadingReport ? "Exportando registros" : "Exportar registros"}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead

                headLabel={TABLE_HEAD}

              />
              <TableBody>
                {participantsList.length > 0 && participantsList.map((row) =>

                (
                  <TableRow hover key={row._id} tabIndex={-1} role="checkbox">

                    <TableCell component="th" scope="row" padding="normal">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2" noWrap>
                          {`${row.nombre} ${row?.apellido || ''}`}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">
                      {row?.documento || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.cargo || "N/A"}
                    </TableCell>

                    <TableCell align="left">
                      {row?.confirmado ?

                        <Button sx={{ padding: 0, fontSize: 10, width: 100 }}
                          variant="contained"
                          type="submit"
                          disabled
                        >
                          Visita confirmada
                        </Button>

                        :

                        <Button sx={{ padding: 0, fontSize: 10, width: 100 }}
                          onClick={() => { confirm(row._id) }}
                          variant="contained"
                          type="submit"
                        >
                          Confirmar visita
                        </Button>

                      }
                    </TableCell>

                    <TableCell align="left">
                      {row?.asiento ||

                        <Button sx={{ padding: 0, fontSize: 10, width: 40 }}
                        onClick={(e) => {setValuesToEdit(row); setOpenModalAddshair(true) }}
                          variant="contained"
                          type="submit"
                        >
                          Asignar
                        </Button>
                      }
                    </TableCell>

                    <TableCell align="left">
                      {row.genero === 'M' ? 'Masculino' : 'Femenino'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.celular || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.email || 'N/A'}
                    </TableCell>


                    <TableCell align="right">
                      <IconButton size="large" color="inherit" onClick={(e) => { handleOpenMenu(e, row); setValuesToEdit(row) }}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>

              {
                loading === true &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />

                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

              {
                (participantsList.length === 0 && loading === false) &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <Typography variant="h6" paragraph>
                          No hay registros
                        </Typography>

                        <Typography variant="body2">
                          No se encontraron registros para esta actividad.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}

            sx={{ float: "right", margin: 2, color: "#ffe7d9" }}
          />

        </Card>
      </Container>


      <CustomModal
        open={addShair}
        modalStyle={{ maxWidth: 800, minWidth: 440 }}
        title={"Editar registro"}
      >
        <FormCreateActivities
          onClose={() => setOpenModalAddshair(false)}
          getValues={() => {
            getAsistants();
            setOpenModalAddshair(false);
            setValuesToEdit({});
            setOpen(null);
          }}
          valuesToEdit={valuesToEdit}
        />
      </CustomModal>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 160,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModalAddshair(true);
            setOpen(false);
          }}>
          <Iconify icon={'pepicons-pop:people'} sx={{ mr: 2 }} />
          Editar registro
        </MenuItem>
      </Popover>
    </>
  );
}
